<template>
    <div>
        <div class="card st-framework-card">

            <div class="card-header">
                <table style="border-collapse: collapse; width: 100%;">
                    <tr>
                        <td style="margin: 0; padding: 0 0 0 7px; width: 40px;" v-if="environment === 'Local'">
                            <button type="button" class="btn btn-dark btn-sm" v-on:click="reloadPluginFile('RFEM.rb')">
                                <font-awesome-icon :icon="['fas','sync']"/>
                            </button>
                        </td>
                        <td style="margin: 0; padding: 0 0 0 1rem; width: 40px;" v-else>
                            <font-awesome-icon :icon="icon" size="sm"/>
                        </td>
                        <td style="text-align: center;">
                            {{ $i18n.tc('translations.' + name, 2) }}
                            <template v-if="importFolder"> ({{ importFolder }})</template>
                        </td>
                        <td style="width: 40px;"/>
                    </tr>
                </table>
            </div>

            <div class="card-body p-0" v-if="pageLoaded">
                <div class="card" style="border: none;">
                    <div class="card-header light p-0" style="border: none;">
                        <table style="width: 100%;">
                            <tr>
                                <td>
                                    <div style="display: inline-block;">
                                        <template v-for="(action,actionIndex) in actions.document">
                                            <template v-if="action.placement === 'DOCUMENTHEADERLEFT'">
                                                <ButtonAction :key="action.name+'-'+actionIndex"
                                                              :action="action" :disabled="state.disabled"
                                                              v-on:import-data="getRFEMData"
                                                              v-if="action.type === 'BUTTON'"/>
                                            </template>
                                        </template>
                                    </div>
                                    <div class="ml-3" style="display: inline-block; vertical-align: middle; color: red;" v-if="missingComponents">
                                        {{ missingComponents }} {{ $i18n.tc('translations.Component not found in RFEM components directory', missingComponents) }}
                                    </div>
                                </td>
                                <td>
                                    <div class="float-right">
                                        <template v-if="generatingModel">
                                            <div class="mr-2" style="display: inline-block; vertical-align: middle;">
                                                <progress id="progress" :value="progressValue" :max="progressMax"/>
                                            </div>
                                            <div class="mr-3" style="display: inline-block;">
                                                {{ Math.round(this.progressValue * 100 / this.progressMax) }}%
                                            </div>
                                        </template>
                                        <div style="display: inline-block;">
                                            <template v-for="(action,actionIndex) in actions.document">
                                                <template v-if="action.placement === 'DOCUMENTHEADERRIGHT'">
                                                    <ButtonAction :key="action.name+'-'+actionIndex"
                                                                  :action="action" :disabled="state.disabled || !processedComponents.length"
                                                                  v-on:generate-model="generateModel"
                                                                  v-if="action.type === 'BUTTON'"/>
                                                </template>
                                            </template>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <template v-if="RFEMError">
                    <div class="card">
                        <div class="card-header light" style="color: red;">
                            {{ RFEMError }}
                        </div>
                    </div>
                </template>

                <Table :key="'component-table-'+reloadTable" header-class="light" footer-class="light" body-class=""
                       with-filter with-pagination pagination-always-visible no-state-loading autofocus highlight-rows-on-click
                       :lines-per-page=data.crudLinesPerPage
                       :rows="processedComponents"
                       :fields="fields.table" :actions="actions.table" :state="state"
                       v-on:clear-selection="clearSelection"
                       v-on:click-row="clickRow"
                       v-on:renumber-definitions="renumberDefinitions"
                       v-on:select-all="selectAll"
                       v-on:select-components="selectComponents"
                       v-on:zoom-component="zoomComponent"
                       v-on:zoom-reset="zoomReset"/>
            </div>

        </div>
    </div>
</template>

<script>
import ButtonAction from "@/components/actions/ButtonAction.vue";
import SketchUp from "@/mixins/SketchUp";
import Table from "@/components/Table";

/* global sketchup:false */

export default {
    name: 'RFEM',
    components: {
        ButtonAction,
        Table,
    },
    props: ['name', 'icon', 'api', 'state', 'environment'],
    mixins: [SketchUp],
    data() {
        return {
            pageLoaded: false,
            fields: null,
            actions: null,
            importFolder: null,
            reloadTable: 0,
            missingComponents: 0,
            generatingModel: false,
            progressValue: 0,
            progressMax: 0,
            processedComponents: [],
            componentsToGenerate: [],
            RFEMError: null,
            renumberStart: 1,
        }
    },
    created() {
        this.state.loading = true;
        window.vm.Tool = this;
        this.fetchData();
    },
    methods: {
        addRFEMComponents(clear) {
            if (this.componentsToGenerate.length > 0) {
                this.generatingModel = true;
                this.RFEMError = null;
                this.state.loading = true;
                this.progressValue = this.progressMax - this.componentsToGenerate.length;
                this.$nextTick(() => {
                    sketchup.addRFEMComponents(clear, this.data.componentLibraryLocation, [this.componentsToGenerate.shift()]);
                });
            } else {
                this.reloadTable++;
                this.generatingModel = false;
                this.state.loading = false;
            }
        },
        addRFEMComponentsCallback(data) {
            let response = JSON.parse(data);
            if (response.error) {
                this.RFEMError = response.error_message;
                this.generatingModel = false;
                this.state.loading = false;
                return;
            }

            this.$worker.run((processedComponents, components) => {
                components.forEach(component => {
                    let idx = processedComponents.findIndex(item => item.id === component.id);
                    processedComponents[idx].entity_ids = component.entity_ids;
                    processedComponents[idx].definition = component.definition;
                    processedComponents[idx].disable_detail_actions = component.entity_ids.length === 0;
                });

                return processedComponents;
            }, [this.processedComponents, response.components]).then(data => {
                this.processedComponents = data;
                this.addRFEMComponents(false);
            }).catch(error => {
                console.log("FREM:addRFEMComponentsCallback():error:", error);
            });
        },
        clearSelection() {
            sketchup.clearSelection();
        },
        clickRow(component) {
            if (component.entity_ids && component.entity_ids.length > 0) {
                sketchup.selectComponents(component.entity_ids, true);
            }
        },
        fetchData() {
            this.$http.get(this.api + '/manage_rfem', {}).then((res) => {
                this.actions = res.data.actions;
                this.fields = res.data.fields;
                this.data = res.data.data;
                this.reloadTable++;
                this.pageLoaded = true;
                this.state.loading = false;
            }).catch((error) => {
                console.log("RFEM:fetchData():error:", error);
            });
        },
        generateModel(data) {
            let noRowsSelected = true;
            this.processedComponents.forEach(component => {
                if (component.selected) {
                    noRowsSelected = false;
                }
            });

            this.componentsToGenerate = [];
            this.processedComponents.forEach(component => {
                if (noRowsSelected || component.selected) {
                    this.componentsToGenerate.push(component);
                }
            });
            this.progressMax = this.componentsToGenerate.length;

            this.addRFEMComponents(data['clear_model'] === '1');
        },
        getModelDefinitionsCallback(definitions) {
            let definitionNamesToProcess = [];
            this.processedComponents.forEach(component => {
                if ('definition' in component) {
                    let idx = component.definition.lastIndexOf("#");
                    let definitionName = idx === -1 ? component.definition : component.definition.substring(0, idx);
                    if (!definitionNamesToProcess.includes(definitionName)) {
                        definitionNamesToProcess.push(definitionName);
                    }
                }
            });
            let definitionsToRenumber = this.getDefinitionsToRenumber(definitions, definitionNamesToProcess, this.renumberStart);
            sketchup.renameDefinitions(definitionsToRenumber);
        },
        getRFEMData(data) {
            this.missingComponents = 0;
            this.importFolder = null;
            this.processedComponents = [];
            this.RFEMError = null;
            this.state.loading = true;
            sketchup.getRFEMData(this.data.dropBoxLocation, this.data.componentLibraryLocation, data['sets_of_members'] === '1');
        },
        getRFEMDataCallback(data) {
            let response = JSON.parse(data);
            if (response.error) {
                this.RFEMError = response.error_message;
                this.state.loading = false;
                return;
            }

            this.$worker.run((components) => {
                let processedComponents = [];

                components.forEach(component => {
                    let newComponent = {
                        id: processedComponents.length + 1,
                        selected: false,
                        filename: component.filename,
                        exists: component.exists,
                        length: component.length,
                        instances_in_model: component.instances.length,
                        instances: component.instances,
                        disable_detail_actions: true,
                    }
                    if (!component.exists) {
                        newComponent.filename_style = "background: red; color: white;";
                    }

                    processedComponents.push(newComponent);
                });

                processedComponents.sort(function (a, b) {
                    if (a.filename.toLowerCase() < b.filename.toLowerCase()) return -1;
                    if (a.filename.toLowerCase() > b.filename.toLowerCase()) return 1;
                    if (parseInt(a.length) < parseInt(b.length)) return -1;
                    if (parseInt(a.length) > parseInt(b.length)) return 1;
                    return 0;
                });

                return processedComponents;
            }, [response.components]).then(data => {
                this.importFolder = response.folder;
                this.processedComponents = data;
                let missingComponents = this.processedComponents.filter(c => !c.exists).map(c => c.filename);
                this.missingComponents = [...new Set(missingComponents)].length;
                this.reloadTable++;
                this.state.loading = false;
            }).catch(error => {
                console.log("FREM:getRFEMDataCallback():error:", error);
            });
        },
        renameDefinitionsCallback(definitions) {
            this.processedComponents.forEach(component => {
                let idx = definitions.findIndex(d => d.old === component.definition);
                if (idx !== -1) {
                    component.definition = definitions[idx].new;
                }
            });
            this.reloadTable++;
            this.state.loading = false;
        },
        renumberDefinitions(data) {
            this.state.loading = true;
            this.renumberStart = data.start;
            sketchup.getModelDefinitions();
        },
        selectAll() {
            sketchup.selectAll();
        },
        selectComponents(component) {
            if (component.entity_ids && component.entity_ids.length > 0) {
                sketchup.selectComponents(component.entity_ids);
            }
        },
        zoomComponent(component) {
            if (component.entity_ids && component.entity_ids.length > 0) {
                sketchup.renderingOption('InactiveHidden', true);
                sketchup.renderingOption('InstanceHidden', true);
                sketchup.zoomExtents(component.entity_ids[0]);
            }
        },
        zoomReset() {
            sketchup.renderingOption('InactiveHidden', false);
            sketchup.renderingOption('InstanceHidden', false);
            sketchup.zoomExtents();
        },
    }
}
</script>

<style scoped>
</style>